.segment-card-field {
  margin: 0;
}
.segment-card-field .sc-header {
  opacity: 0.5;
  font-size: 12px;
  float: right;
}
.segment-card-field .sc-body {
  font-size: 20px;
  text-align: bottom;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.segment-card-field .sc-body .tooltip-text {
  position: absolute;
  width: 100%;
  top: -20px;
  background-color: var(--seg-box-light-gray);
  color: #fff;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  font-weight: normal;
  z-index: 1000 !important;
}
.segment-card-field .sc-body .volume-percentage {
  font-weight: 300;
  font-size: 16px;
}
.segment-card-field .sc-body .volume {
  display: flex;
}
.segment-card-field .sc-body .volume .astrix {
  font-weight: 100;
  margin-left: 3px;
}
.segment-card-field .sc-body .origin {
  font-size: 14px;
  font-weight: 500;
  margin: 3px 0;
  display: flex;
  align-items: center;
}
.segment-card-field .sc-body .origin span {
  opacity: 0.5;
  display: inline !important;
  margin-right: 3px;
}
.segment-card-field .sc-body .origin .modelinfo {
  color: rgba(255,255,255,0.5);
  margin-left: 5px;
  cursor: pointer;
}
.segment-card-field .sc-body .origin .modelinfo .tooltip-container {
  display: flex;
}
.segment-card-field .sc-body .cdt-table {
  display: flex;
  justify-content: space-between;
  color: #000;
  position: relative;
}
.segment-card-field .sc-body .cdt-table .tooltip-text {
  width: 100px;
}
.segment-card-field .sc-body .cdt-table .column {
  color: #fff;
  position: relative;
  border-top: 1px solid rgba(255,255,255,0.5);
  border-bottom: 1px solid rgba(255,255,255,0.5);
}
.segment-card-field .sc-body .cdt-table .column:first-child {
  border-radius: 5px 0 0 5px;
  border-left: 1px solid rgba(255,255,255,0.5);
  font-weight: 400;
}
.segment-card-field .sc-body .cdt-table .column:last-child {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid rgba(255,255,255,0.5);
}
.segment-card-field .sc-body .cdt-table .column:not(:last-child) {
  border-right: 1px solid rgba(255,255,255,0.5);
}
.segment-card-field .sc-body .cdt-table .column .title,
.segment-card-field .sc-body .cdt-table .column .value {
  text-align: center;
  padding: 2px 7px;
  cursor: default;
  font-size: 12px;
}
.segment-card-field .sc-body .cdt-table .column .value {
  padding-top: 3px;
}
.segment-card-field .sc-body .cdt-table .column .title {
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
}
.segment-card-field .sc-body .cdt-table .column.selected {
  padding: 1px 0;
  border: none;
  outline: 4px solid #497a00;
  z-index: 999 !important;
}
.lesion-title-extension {
  display: inline;
}
.lesion-title-extension .delimiter {
  margin-left: 3px;
}
.lesion-title-extension .title-extension {
  margin-left: 3px;
  opacity: 0.5;
}
.merge-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  background-color: #424242;
  border-top-left-radius: var(--steep-border-radius);
  border-bottom-left-radius: var(--steep-border-radius);
}
.merge-section .custom-checkbox {
  border: 1px solid #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: grid;
  place-content: center;
}
.merge-section .custom-checkbox:hover {
  border: 1px solid var(--alta-color);
}
.merge-section .custom-checkbox.checked::before {
  content: "";
  width: 0.75em;
  height: 0.75em;
  transition: 120ms transform ease-in-out;
  border-radius: 50%;
  box-shadow: inset 0 0 1em 1em #f00;
}
