.brushSizeSlider {
  width: 100px;
  margin-left: 30px;
}
.brushSizeSlider .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.brushSizeSlider .container .toolbar-slider-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.brushSizeSlider .container .toolbar-slider-container label {
  font-weight: 700;
  margin-bottom: 10px;
}
.brushSizeSlider .container .toolbar-button-label {
  margin-top: 10px;
}
