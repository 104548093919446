.textField {
  position: relative;
  box-shadow: none;
}
.textField input {
  position: relative;
  height: 40px;
  margin: 0 5px 20px 5px;
  padding: 0 20px;
  cursor: pointer;
  border: none;
  background-color: var(--input-background-color);
  color: var(--input-placeholder-color);
  font-size: 10pt;
  font-weight: normal;
  border-radius: 4px;
  width: calc(100% - 10px); /* Just use padding? */
  box-shadow: var(--inner-box-shadow);
}
.textField input:focus {
  box-shadow: var(--inner-box-shadow);
}
.textField .icon {
  opacity: 0.7;
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--input-placeholder-color);
}
