* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.emph {
  font-weight: 700;
}
.block {
  display: block;
}
.form-themed table {
  color: var(--text-primary-color);
}
.form-themed table th,
.form-themed table td {
  font-size: 15px;
  font-weight: normal;
}
.form-themed table th {
  line-height: 60px;
}
.form-themed table td {
  line-height: 18px;
  padding: 0;
}
.form-themed .p-r-1 {
  padding-right: 1rem !important;
}
.form-themed label {
  margin-bottom: 5px;
}
.form-themed label.wrapperLabel {
  cursor: pointer;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) .wrapperText {
  display: block;
  order: -1;
}
.form-themed .btn[disabled],
.form-themed input[type=text][disabled],
.form-themed input[type=password][disabled],
.form-themed input[type=number][disabled],
.form-themed .btn.disabled,
.form-themed input[type=text].disabled,
.form-themed input[type=password].disabled,
.form-themed input[type=number].disabled,
.form-themed .btn[disabled]:hover,
.form-themed input[type=text][disabled]:hover,
.form-themed input[type=password][disabled]:hover,
.form-themed input[type=number][disabled]:hover,
.form-themed .btn.disabled:hover,
.form-themed input[type=text].disabled:hover,
.form-themed input[type=password].disabled:hover,
.form-themed input[type=number].disabled:hover,
.form-themed .btn[disabled]:active,
.form-themed input[type=text][disabled]:active,
.form-themed input[type=password][disabled]:active,
.form-themed input[type=number][disabled]:active,
.form-themed .btn.disabled:active,
.form-themed input[type=text].disabled:active,
.form-themed input[type=password].disabled:active,
.form-themed input[type=number].disabled:active {
  background-color: var(--ui-gray-darker);
  border-color: var(--ui-gray-light);
  color: var(--text-primary-color);
}
.form-themed .btn[disabled] + .wrapperText,
.form-themed input[type=text][disabled] + .wrapperText,
.form-themed input[type=password][disabled] + .wrapperText,
.form-themed input[type=number][disabled] + .wrapperText,
.form-themed .btn.disabled + .wrapperText,
.form-themed input[type=text].disabled + .wrapperText,
.form-themed input[type=password].disabled + .wrapperText,
.form-themed input[type=number].disabled + .wrapperText,
.form-themed .btn[disabled]:hover + .wrapperText,
.form-themed input[type=text][disabled]:hover + .wrapperText,
.form-themed input[type=password][disabled]:hover + .wrapperText,
.form-themed input[type=number][disabled]:hover + .wrapperText,
.form-themed .btn.disabled:hover + .wrapperText,
.form-themed input[type=text].disabled:hover + .wrapperText,
.form-themed input[type=password].disabled:hover + .wrapperText,
.form-themed input[type=number].disabled:hover + .wrapperText,
.form-themed .btn[disabled]:active + .wrapperText,
.form-themed input[type=text][disabled]:active + .wrapperText,
.form-themed input[type=password][disabled]:active + .wrapperText,
.form-themed input[type=number][disabled]:active + .wrapperText,
.form-themed .btn.disabled:active + .wrapperText,
.form-themed input[type=text].disabled:active + .wrapperText,
.form-themed input[type=password].disabled:active + .wrapperText,
.form-themed input[type=number].disabled:active + .wrapperText {
  color: var(--text-primary-color);
  cursor: auto;
}
.form-themed input[type=text],
.form-themed input[type=password],
.form-themed input[type=number] {
  background-color: var(--ui-gray);
  border-color: var(--ui-border-color);
  color: var(--text-primary-color);
  font-weight: normal;
}
.form-themed input[type=text]:active,
.form-themed input[type=password]:active,
.form-themed input[type=number]:active,
.form-themed input[type=text]:focus,
.form-themed input[type=password]:focus,
.form-themed input[type=number]:focus {
  background-color: var(--ui-gray-dark);
  border: 2px solid var(--active-color);
  box-shadow: none;
}
.form-themed input[type=text]:active + .wrapperText,
.form-themed input[type=password]:active + .wrapperText,
.form-themed input[type=number]:active + .wrapperText,
.form-themed input[type=text]:focus + .wrapperText,
.form-themed input[type=password]:focus + .wrapperText,
.form-themed input[type=number]:focus + .wrapperText {
  color: var(--active-color);
}
.form-themed .btn-primary {
  background-color: var(--active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .btn-primary:hover,
.form-themed .btn-primary:active,
.form-themed .btn-primary:focus,
.form-themed .btn-primary:focus:active {
  background-color: var(--ui-active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .form-control {
  background-color: #fff;
  border: 0;
  font-size: 14px;
  height: 30px;
  line-height: 16px;
  padding: 8px 9px 6px;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
}
.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: ;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: ;
}
input[type=range] {
  display: block;
  width: 100%;
}
.CineDialog {
  background: var(--ui-gray-darkest);
  color: var(--text-secondary-color);
  filter: drop-shadow(0 0 3px var(--ui-gray-darkest));
  border: none;
  border-radius: 8px;
  width: inherit;
}
.CineDialog h5 {
  font-size: 20px;
  line-height: 35px;
  margin: 0;
}
.CineDialog h5,
.CineDialog label {
  font-weight: 400;
}
.CineDialog .cine-navigation,
.CineDialog .cine-controls,
.CineDialog .cine-options {
  cursor: default;
}
.CineDialog .fps-section input[type="range"] {
  background-color: transparent;
  border: 0 none;
  outline: 0 none;
}
.CineDialog .fps-section input[type="range"]::-ms-tooltip {
  display: none;
}
.CineDialog .double-row-style {
  box-sizing: border-box;
  width: 290px;
  height: 70px;
  padding: 10px;
  top: 2%;
  left: 35%;
}
.CineDialog .double-row-style .cine-navigation {
  position: absolute;
  right: 16px;
  top: 10px;
}
.CineDialog .double-row-style .cine-navigation .btn {
  padding: 0 4px;
}
.CineDialog .double-row-style .cine-navigation .btn i {
  font-size: 32px;
  line-height: 32px;
}
.CineDialog .double-row-style .cine-controls {
  left: 0px;
}
.CineDialog .double-row-style .cine-options {
  padding: 0px 0;
}
.CineDialog .double-row-style .cine-options .fps-section {
  width: 175px;
  float: left;
}
.CineDialog .double-row-style .cine-options .fps {
  float: right;
}
.CineDialog .single-row-style {
  box-sizing: content-box;
  width: 425px;
  height: 45px;
  padding: 4px 8px;
  top: 2%;
  left: 35%;
}
.CineDialog .single-row-style .cine-navigation {
  float: right;
  overflow: hidden;
  padding-right: 12px;
  position: relative;
}
.CineDialog .single-row-style .cine-navigation .btn {
  padding: 0 2px;
  margin: 0 0 0 2px;
  border: 0 none;
}
.CineDialog .single-row-style .cine-navigation .btn:first-of-type {
  margin-left: 0;
}
.CineDialog .single-row-style .cine-navigation .btn i {
  font-size: 32px;
  line-height: 45px;
}
.CineDialog .single-row-style .cine-controls {
  position: relative;
  float: left;
  overflow: hidden;
}
.CineDialog .single-row-style .cine-controls .btn {
  font-size: 22px;
  line-height: 45px;
  min-width: 28px;
  padding: 0 0px;
  margin: 0 4px;
  border: 0 none;
}
.CineDialog .single-row-style .cine-options {
  display: block;
  position: relative;
  width: 150px;
  float: left;
  overflow: hidden;
  padding: 0;
  margin: 0 0 0 10px;
}
.CineDialog .single-row-style .cine-options .fps-section {
  display: block;
  float: left;
  width: 80px;
}
.CineDialog .single-row-style .cine-options .fps-section input[type="range"] {
  line-height: 45px;
  height: 45px;
}
.CineDialog .single-row-style .cine-options .fps {
  display: block;
  width: 68px;
  float: left;
  padding: 0;
  margin: 0 0 0 2px;
  text-align: center;
  line-height: 45px;
  font-size: 14px;
  line-height: 1.42857143;
}
.CineDialog .btn {
  color: var(--text-secondary-color);
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  font-size: 15px;
  width: 45px;
  font-weight: normal;
  height: 37px;
  line-height: 37px;
  padding: 0 12px;
}
.CineDialog .btn:hover,
.CineDialog .btn:active,
.CineDialog .btn.active {
  color: var(--hover-color);
}
.CineDialog .btn[disabled]:hover {
  color: inherit;
}
.CineDialog .btn[disabled]:active {
  color: var(--text-secondary-color);
}
.CineDialog .btn.btn-primary,
.CineDialog .btn.btn-secondary,
.CineDialog .btn.btn-danger {
  transition: background-color 0.3s ease;
}
.CineDialog .btn.btn-primary:hover,
.CineDialog .btn.btn-secondary:hover,
.CineDialog .btn.btn-danger:hover,
.CineDialog .btn.btn-primary:active,
.CineDialog .btn.btn-secondary:active,
.CineDialog .btn.btn-danger:active,
.CineDialog .btn.btn-primary:focus,
.CineDialog .btn.btn-secondary:focus,
.CineDialog .btn.btn-danger:focus {
  opacity: 0.8;
}
.CineDialog .btn.btn-primary {
  background-color: var(--active-color);
  color: var(--primary-background-color);
}
.CineDialog .btn.btn-secondary {
  background-color: var(--text-secondary-color);
  color: var(--primary-background-color);
}
.CineDialog .btn.btn-danger {
  background-color: var(--text-destructive-color);
  color: var(--text-primary-color);
}
.CineDialog .btn.active {
  box-shadow: inset 1px 1px 2px var(--ui-gray-darkest);
}
