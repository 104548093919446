.container > aside {
  color: var(--text-secondary-color);
  min-height: 250px;
  height: 75%;
  display: flex;
  flex-direction: column;
}
.container > aside .file-area-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container > aside .file-area-header .clear-button {
  margin: 0 12px;
  font-size: 12px;
  padding: 0;
  width: 75px;
  align-self: center;
}
.container > aside .scrollArea {
  box-shadow: inset 0 0 8px #000;
  border-radius: 5px;
}
.container > aside .scrollArea .scrollable {
  padding: 0px 15px;
}
.container > aside .scrollArea .scrollNav {
  background-color: transparent;
  box-shadow: none;
  margin: 10px 3px 5px;
}
.container > aside .scrollArea span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.6;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: var(--ui-border-color-active);
  border-style: dashed;
  background-color: #fafafa;
  color: var(--table-text-secondary-color);
  outline: none;
  cursor: pointer;
}
.isFocused {
  border-color: #2196f3;
}
.isDragReject {
  border-color: #00e676;
}
.isDragAccept {
  border-color: #ff1744;
}
.isDragAccept span {
  transition: color 0.24s ease-in-out;
}
.isDragAccept:hover span {
  color: var(--ui-border-color);
  transition: color 0.24s ease-in-out;
}
