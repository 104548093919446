.studyBrowseItem {
  margin-bottom: 10px;
  position: relative;
  transform: scale(1);
}
.studyBrowseItem.active .studyItemBox {
  border-color: var(--active-color);
}
.studyBrowseItem.active .studyItemBox .studyModality {
  color: var(--primary-background-color);
}
.studyBrowseItem.active .studyItemBox .studyModality,
.studyBrowseItem.active .studyItemBox .studyModality:before,
.studyBrowseItem.active .studyItemBox .studyModality:after {
  background-color: var(--active-color);
  border-color: #131d29;
}
.studyBrowseItem:not(.active) .studyBrowserSeries {
  max-height: 0 !important;
}
.studyBrowseItem:not(.active) .studyBrowserSeries .studySeriesContainer {
  opacity: 0;
  transform: translateY(-100%);
}
.studyBrowseItem .studyBrowserSeries {
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.studyBrowseItem .studyBrowserSeries .studySeriesContainer {
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(0);
  transform-origin: 50% 0%;
}
.studyItemBox {
  border: 3px solid var(--ui-border-color-dark);
  border-radius: 12px;
  cursor: pointer;
  padding: 12px 7px 11px;
  position: relative;
  transition: $sidebarTransition;
  background-color: #14191e;
  z-index: 0;
}
.studyItemBox:hover {
  border-color: var(--active-color);
}
.studyItemBox:hover.additional {
  color: var(--text-secondary-color);
}
.studyItemBox:hover .studyModality {
  color: var(--primary-background-color);
}
.studyItemBox:hover .studyModality,
.studyItemBox:hover .studyModality:before,
.studyItemBox:hover .studyModality:after {
  background-color: var(--active-color);
}
.studyItemBox.additional {
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: normal;
  height: 91px;
  line-height: 91px;
  padding: 0;
  text-align: center;
}
.studyItemBox .studyText {
  font-size: 13px;
  left: 89px;
  line-height: 14px;
  position: absolute;
  right: 7px;
  top: 12px;
}
.studyItemBox .studyText .studyDate {
  margin-top: 8px;
  color: var(--text-secondary-color);
}
.studyItemBox .studyText .studyDescription {
  margin-top: 8px;
  color: var(--text-primary-color);
}
.studyItemBox .studyText .studyAvailability {
  margin-top: 24px;
  color: var(--text-primary-color);
}
.studyItemBox .studyModality {
  color: var(--text-secondary-color);
  font-size: 20px;
  line-height: 50px;
  margin-left: 12px;
  margin-top: 12px;
  position: relative;
}
.studyItemBox .studyModality .studyModalityText {
  height: 100%;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
}
.studyItemBox .studyModality,
.studyItemBox .studyModality:before,
.studyItemBox .studyModality:after {
  border: 2px solid var(--primary-background-color);
  background-color: var(--box-background-color);
  border-radius: 8px;
  height: 54px;
  width: 54px;
}
.studyItemBox .studyModality:before,
.studyItemBox .studyModality:after {
  display: block;
  content: '';
  position: absolute;
}
.studyItemBox .studyModality:before {
  top: -8px;
  left: -8px;
  z-index: -1;
}
.studyItemBox .studyModality:after {
  top: -14px;
  left: -14px;
  z-index: -2;
}
