.progress-bar {
  border: 1px solid #000;
  width: 100%;
  margin-top: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 4px #000;
  z-index: 1;
  position: relative;
}
.progress-bar .loading-percentage {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}
.progress-bar .loading-bar {
  margin: 0;
  background-image: linear-gradient(to right, #bcdeff, var(--text-secondary-color));
  height: 100%;
  border-radius: 5px;
  z-index: -1000000000;
}
