.PanelHeader {
  padding-left: 30px;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  gap: 1em;
  background: var(--secondary-background-color);
}
.PanelHeader .PanelHeaderItem {
  display: inline-block;
  margin-top: 9px;
  margin-left: 9px;
  padding-left: 9px;
  width: 120px;
  font-size: 14px;
  line-height: 20px;
  border-left: 1px solid var(--text-secondary-color);
  color: var(--text-primary-color);
}
.PanelHeader .PanelHeaderItem .timepointLabel {
  color: var(--text-secondary-color);
  font-size: 12px;
}
.PanelHeader .inactive {
  opacity: 0.7;
  cursor: none;
  pointer-events: none;
}
.PanelHeader button {
  margin-top: 6px;
  height: 30px;
  width: 120px;
  border: 1px solid var(--ui-border-color-active);
  border-radius: 25px;
  background-color: var(--active-color);
  color: var(--ui-gray-dark);
  line-height: 25px;
  outline: none;
  cursor: pointer;
}
.PanelHeader button:hover,
.PanelHeader button:active {
  background-color: var(--ui-sky-blue);
}
