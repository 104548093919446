.segment-detail-card {
  padding: var(--card-padding);
  padding-top: 0;
}
.segment-detail-card .heading {
  margin-bottom: 5px;
  font-size: var(--default-font-size);
}
.segment-detail-card-row-body {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 3px;
}
