.selector {
  background-color: var(--seg-box-light-gray) !important;
  margin-bottom: 5px;
  flex-grow: 1;
}
.selector > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}
.selector .selector-overview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selector .selector-overview .icon {
  opacity: 0.3;
}
.selector .selector-overview .icon:hover {
  opacity: 1;
}
.selector .title {
  opacity: 0.5;
  font-weight: 300;
  font-size: var(--default-font-size);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.selector .selector-expanded .option {
  cursor: pointer;
  opacity: 0.5;
}
.selector .selector-expanded .option:hover {
  opacity: 1;
}
.incremental .selector-right > .icon-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
}
.incremental .selector-right > .icon-group .icon {
  width: 20px;
  height: 20px;
}
.incremental .selector-right > .icon-group .icon.inactive {
  pointer-events: none;
  opacity: 0;
}
.multi-choice-selector .checkbox-wrapper {
  display: inline-block;
}
.multi-choice-selector input[type="checkbox"] {
  display: grid;
  place-content: center;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 0.15em solid #fff;
  border-radius: 0.15em;
}
.multi-choice-selector input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #f00;
}
.multi-choice-selector input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.multi-choice-selector label {
  display: inline;
  margin-left: 10px;
}
.multi-choice-selector .textarea-ohif {
  margin-top: 5px;
  border-radius: var(--steep-border-radius);
  padding: 0 5px;
}
.multi-choice-selector .custom-choice {
  display: flex;
  align-items: center;
}
.multi-choice-selector .custom-choice .textarea-wrapper {
  margin-right: 10px;
  display: inline-block;
  max-width: calc(100% - 20px);
}
.multi-choice-selector .custom-choice .textarea-wrapper .emptyValue {
  pointer-events: none;
  margin-top: 5px;
  top: 2px;
  left: 5px;
}
.multi-choice-selector .custom-choice .button-wrapper {
  display: inline-block;
}
.multi-choice-selector .custom-choice button {
  height: 20px;
  display: grid;
  place-content: center;
}
.multi-choice-selector .custom-choice button::before {
  width: 0.65em;
  height: 0.65em;
  box-shadow: inset 1em 1em #fff;
  content: "";
  transition: 120ms transform ease-in-out;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.multi-choice-selector .custom-choice button:hover::before {
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--seg-box-light-gray);
}
