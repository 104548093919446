.loading-spinner-notifiaction-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 40px;
}
.loading-spinner-notifiaction-card .lsnc-text {
  max-width: 80%;
}
.loading-spinner-notifiaction-card .loading-spinner-wrapper {
  position: relative;
}
.loading-spinner-notifiaction-card .loading-spinner:after {
  border-color: #fff transparent #fff transparent;
}
