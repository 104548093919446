.sub-volume-fields {
  display: flex;
  margin-top: 5px;
}
.sub-volume-fields > :not(:first-child) {
  margin-left: 8px;
}
.sub-volume-fields .input-area {
  display: flex;
  align-items: center;
}
.sub-volume-fields .input-area .textarea-wrapper {
  width: 50%;
}
.sub-volume-fields .input-area .textarea-ohif {
  border-radius: var(--steep-border-radius);
}
.sub-volume-fields .input-area .textarea-ohif.valid {
  border: 2px solid #008000;
}
.sub-volume-fields .input-area .textarea-ohif.invalid {
  border: 2px solid #f00;
}
.sub-volume-fields .input-area .unit {
  margin-left: 5px;
  font-weight: 500;
}
.button-area {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: 10px;
  margin: 0 5px;
}
.button-area button {
  flex: 1;
}
.sub-volume-fields > * {
  flex: 1;
  min-width: 0;
}
