.Badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  border-radius: 25px;
  z-index: 1;
  font-size: 10px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
