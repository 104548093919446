.option-picker-area {
  height: 40px;
  margin: 0 5px 20px 5px;
  padding: 3px 5px;
  position: relative;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--input-background-color);
  color: var(--input-placeholder-color);
  font-size: 10pt;
  font-weight: normal;
  border-radius: 4px;
  width: calc(100% - 10px); /* Just use padding? */
  box-shadow: var(--inner-box-shadow);
}
.option-picker-area:active,
.option-picker-area:hover {
  background-color: var(--input-background-color);
}
.selected-options-area {
  display: flex;
  overflow: hidden;
}
.selected-options-area .option-tag {
  margin-right: 5px;
}
.overshoot {
  margin: auto 0;
  margin-left: 10px;
}
.icon {
  cursor: pointer;
  opacity: 0.7;
}
.icon:hover {
  color: var(--alta-color);
}
.option-tag-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--input-background-color);
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 5px;
  box-shadow: var(--inner-box-shadow);
  padding: 5px;
}
.option-tag-container .option-tag-area {
  display: inline-block;
}
.option-tag-container .option-tag-area .option-tag {
  margin-top: 5px;
  margin-right: 5px;
}
.option-tag-container .option-tag-area-footer {
  margin-top: 5px;
  float: right;
}
.option-tag-container .option-tag-area-footer .icon {
  float: right;
  margin-bottom: 10px;
  margin-right: 10px;
}
.option-tag {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  border: 1px dashed #fff;
  cursor: pointer;
  white-space: nowrap;
}
.option-tag span {
  opacity: 0.7;
}
.option-tag .icon {
  vertical-align: middle;
  margin-left: 15px;
}
.option-tag:hover {
  color: var(--active-color);
  border-color: var(--active-color);
}
.option-tag:hover span {
  opacity: 1;
}
.option-tag.selected {
  background-color: var(--table-text-secondary-color);
  color: #000;
  border: none;
  cursor: auto;
}
