.report-panel {
  line-height: 1;
  background-color: var(--primary-background-color);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--panel-padding);
  font-size: 16px;
  position: relative;
}
.report-panel .row {
  margin-left: 0;
  margin-right: 0;
}
.report-panel .row .body {
  margin-left: 5px;
}
.report-panel .alt-text {
  color: #fff;
}
.report-panel .drop-down-panel {
  margin-bottom: var(--card-heading-margin-bottom);
}
.report-panel .download-pdf-btn {
  padding: 5px;
  margin: 40px 0;
}
.report-panel .panel-area {
  margin-top: var(--card-heading-margin-bottom);
  cursor: default;
}
.report-panel .card-heading {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 25px;
}
.report-panel .card {
  color: #fff;
  background-color: var(--box-background-color);
  border-radius: var(--steep-border-radius);
  padding: var(--card-padding);
}
.report-panel .card-row {
  display: flex;
  justify-content: space-between;
  gap: 0;
  margin-top: 10px;
}
.report-panel .card-row > .info-panel {
  margin: 0 5px;
  width: 100%;
}
.report-panel .card-row > .info-panel:first-child {
  margin-left: 0;
}
.report-panel .card-row > .info-panel:last-child {
  margin-right: 0;
}
.report-panel .card-table > .card-row-row:first-child {
  margin-top: 0;
}
.report-panel .card-table .text-header {
  font-size: 16px;
  opacity: 0.6;
  margin-bottom: 4px;
}
.report-panel .card-table .title {
  margin-top: 8px;
}
.report-panel .card-table .text-body {
  font-size: 18px;
  font-weight: 700;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin-top: 4px;
}
.report-panel .card-table .text-body.row {
  display: inline-block;
}
.report-panel .card-table .text-body.row:not(:last-child) {
  margin-right: 5px;
}
.report-panel .card-table .therapy:not(:first-of-type) {
  margin-top: 8px;
}
.report-panel .card-table .therapy .therapy-title {
  text-decoration: underline;
  margin-bottom: 8px;
}
.report-panel .card-table .therapy > .row {
  display: flex;
  margin-bottom: 5px;
}
.report-panel .card-table .therapy .title {
  width: 25%;
  min-width: 25%;
  margin-top: 5px;
}
