.segmentation-panel {
  color: #fff;
  min-height: 200px;
  background-color: var(--primary-background-color);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--panel-padding);
  position: relative;
  user-select: none;
  flex-grow: 1;
}
.segmentation-panel .segmentation-panel-inner {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: column;
  max-height: 95%;
}
.segmentation-panel .segmentation-panel-body {
  max-height: 90%;
  flex: 1;
}
.segmentation-panel .icon {
  cursor: pointer;
  transition: all 0.3s ease;
}
.segmentation-panel .icon:hover {
  color: var(--alta-color);
  transition: all 0.3s ease;
}
.segmentation-panel .icon.inactive {
  opacity: 0.3;
  pointer-events: none;
}
.segmentation-panel .cog-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}
.segmentation-panel .scroll-container {
  max-height: 105%;
  margin-bottom: 5px;
}
.segmentation-panel .panel-heading {
  margin-bottom: var(--title-margin-bottom);
}
.segmentation-panel .seg-panel-card {
  margin-bottom: 10px;
  background-color: var(--box-background-color);
  padding: var(--card-padding);
  border-radius: var(--steep-border-radius);
}
.segmentation-panel .seg-panel-card .seg-panel-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
}
.segmentation-panel .seg-panel-card .seg-panel-card-body {
  display: flex;
  flex-direction: column;
}
.segmentation-panel .seg-panel-card .seg-panel-card-body > * {
  margin-top: 10px;
}
.segmentation-panel .seg-panel-card .icon {
  width: calc(var(--card-icon-size) * 1.2);
  height: calc(var(--card-icon-size) * 1.2);
}
.segmentation-panel .seg-panel-card .icon.small {
  width: var(--card-icon-size);
  height: var(--card-icon-size);
}
.segmentation-panel .seg-panel-card .icon.right {
  float: right;
}
.segmentation-panel .seg-panel-card .icon-group {
  display: flex;
  align-items: center;
}
.segmentation-panel .seg-panel-card .icon-group .icon-group-element {
  margin-left: 15px;
}
.segmentation-panel .seg-panel-card .icon-group .segment-count {
  font-size: 18px;
}
.segmentation-panel .seg-panel-card .seg-panel-card-footer-button {
  margin: 8px 0 0 auto;
  padding: 10px 15px;
  opacity: 0.5;
  pointer-events: none;
}
.segmentation-panel .seg-panel-card .seg-panel-card-footer-button.active {
  opacity: 1;
  pointer-events: all;
}
.segmentation-panel .segment-panel-box {
  background-color: var(--seg-box-gray);
  padding: var(--card-padding);
  border-radius: var(--steep-border-radius);
}
.segmentation-panel .segment-panel-box .title {
  font-size: 16px;
  font-weight: 500;
}
.segmentation-panel-footer button {
  display: block;
  margin: auto;
  padding: 10px;
  width: 90%;
}
.study-context-area button {
  width: 90%;
}
.missing-segmentation .block {
  margin-top: 10px;
}
.missing-segmentation .button-area {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.missing-segmentation .button-area button {
  margin-top: 10px;
  padding-bottom: 5px;
}
