.drop-down-panel {
  background-color: var(--seg-box-gray);
  padding: var(--card-padding);
  border-radius: var(--steep-border-radius);
  margin-bottom: 10px;
}
.drop-down-panel .title {
  font-size: 16px;
  font-weight: 500;
}
.drop-down-panel .dropdown-status {
  align-self: center;
  margin: auto 10px;
  color: #008000;
}
.drop-down-panel .recommendation-preview {
  opacity: 0.5;
}
.drop-down-panel .open-selector:first-child {
  margin-top: 10px;
}
.drop-down-panel .choice-opt {
  cursor: pointer;
  color: #fff;
  width: 100%;
  background-color: var(--box-background-color);
  border-radius: var(--steep-border-radius);
  padding: var(--card-padding);
  transition: all 0.3s ease;
}
.drop-down-panel .choice-opt span {
  opacity: 0.7;
}
.drop-down-panel .choice-opt.highlighted {
  color: #008000;
  border: 1px solid #008000;
}
.drop-down-panel .choice-opt:hover {
  transition: all 0.3s ease;
  filter: brightness(1.2);
}
.drop-down-panel .choice-opt span {
  opacity: 1;
}
.report-modal-body .further-settings {
  font-size: 16px;
}
.report-modal-body .further-settings .title {
  font-size: 20px;
  font-weight: 500;
}
.report-modal-body .further-settings .ohif-check-container {
  width: 45%;
  margin-right: 5%;
  box-sizing: border-box;
  padding: 10px;
}
.report-modal-body .further-settings .ohif-check-container .ohif-check-label {
  font-size: 14px;
}
.report-modal-footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
