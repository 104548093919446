* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.emph {
  font-weight: 700;
}
.block {
  display: block;
}
:root {
  --series-count-background-color: #678696;
  --active-color: #20a5d6;
}
.thumbnail {
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 0;
  position: relative;
  border-bottom: 1px solid var(--ui-gray);
}
.thumbnail .alt-image-text {
  background-color: var(--primary-background-color);
  position: relative;
  transition: var(--sidebar-transition);
  padding: 2px;
}
.thumbnail .alt-image-text * {
  text-align: center;
  color: var(--text-primary-color);
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  margin: 0;
}
.thumbnail .alt-image-text .alt-image-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  height: 74px;
  width: 74px;
}
.thumbnail .alt-image-text h1 {
  font-size: 14px;
}
.thumbnail .alt-image-text h2 {
  font-size: 12px;
  opacity: 0.7;
}
.thumbnail .series-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--text-primary-color);
  font-size: 14px;
  line-height: 1.3em;
  width: 100%;
  margin-top: 5px;
  margin-left: 1rem;
  margin-right: 0.5rem;
  position: relative;
  word-wrap: break-word;
  padding-bottom: 10px;
}
.thumbnail .series-details .series-description {
  max-width: 18rem;
}
.thumbnail .series-details.info-only .series-description {
  display: none;
}
.thumbnail .series-information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.thumbnail .series-information .item-frames .icon {
  height: 18px;
}
.thumbnail .series-information .warning {
  margin: auto 0;
  opacity: 1;
  color: #e29e4a;
}
.thumbnail .series-information .warning svg {
  width: 16px;
  height: 14px;
  pointer-events: inherit;
}
.thumbnail .series-information .value {
  color: var(--text-secondary-color);
  display: inline-block;
  font-size: 12px;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 15px);
  vertical-align: middle;
}
.thumbnail .series-information .icon {
  color: var(--active-color);
  display: inline-block;
  font-size: 10px;
  font-weight: 900;
  text-align: right;
  width: 11px;
}
.thumbnail .series-information .image-frames .icon {
  color: var(--default-color);
}
.thumbnail.active {
  transition: var(--sidebar-transition);
  background-color: var(--ui-gray-dark);
}
.thumbnail.displayed .image-frames .icon {
  color: var(--active-color);
}
