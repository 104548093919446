.nav-tabs {
  border-bottom: 0;
  margin-bottom: 3px;
  position: relative;
  z-index: 1;
}
.nav-tabs :hover {
  cursor: pointer;
}
.nav-tabs>li {
  font-size: 14px;
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  margin-bottom: 0;
}
.nav-tabs>li>button {
  display: block;
  width: 100%;
  line-height: inherit;
  margin: 0;
  height: inherit;
  padding: 0 10px 0 10px;
  outline: none;
}
.nav-tabs>li>button:after {
  background-color: transparent;
  bottom: -3px;
  content: ' ';
  display: block;
  height: 3px;
  width: 100%;
}
.nav-tabs>li>button,
.nav-tabs>li.active>button,
.nav-tabs>li>button:hover,
.nav-tabs>li.active>button:hover,
.nav-tabs>li>button:active,
.nav-tabs>li.active>button:active,
.nav-tabs>li>button:focus,
.nav-tabs>li.active>button:focus {
  color: var(--active-color);
  background-color: transparent;
  border: 0;
}
.nav-tabs>li.active>button {
  font-weight: bold;
}
.nav-tabs>li.active>button:after {
  background-color: var(--active-color);
}
.nav {
  padding-left: 0;
  list-style: none;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li {
  float: left;
  margin-bottom: -1px;
}
.TabComponents .TabComponents_tabHeader {
  display: flex;
  flex-direction: column;
  margin-left: -20px;
  margin-right: -20px;
}
.TabComponents .TabComponents_tabHeader_selector {
  border-bottom: 3px solid #000;
  padding: 0 25px;
}
.TabComponents .TabComponents_content {
  min-height: 450px;
  display: none;
}
.TabComponents .TabComponents_content.active {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
