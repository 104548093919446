.loading-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -22px 0 0 -22px;
  display: block;
  width: 44px;
  height: 44px;
}
.loading-spinner:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid var(--alta-color);
  border-color: var(--alta-color) transparent var(--alta-color) transparent;
  animation: loading-spinner 1.2s linear infinite;
}
@-moz-keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
