.study-info-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 10px;
  min-height: 65px;
  background-color: var(--ui-gray-dark);
}
.study-info-summary .sis-row {
  color: var(--default-color);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
