.scroll-container {
  overflow: auto;
  scrollbar-width: none;
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
