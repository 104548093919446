.sequenceTableWrapper {
  background-color: var(--primary-background-color);
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: var(--panel-padding);
}
.sequenceTableWrapper .heading {
  margin-bottom: var(--title-margin-bottom);
}
.show-more-toggle {
  margin-top: 25px;
  padding-left: 15px;
  color: #fff;
  align-items: center;
  display: flex;
  transition: all 0.2s ease-out;
  cursor: pointer;
}
.show-more-toggle:hover {
  color: var(--alta-color);
  transition: all 0.2s ease-out;
}
.hidden-selectors {
  margin-top: 25px;
}
.submit-btn {
  margin-top: 15px;
  padding: 10px;
  width: 90%;
}
