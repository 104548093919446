.combined-volume-card {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 24px;
  opacity: 0.5;
}
.combined-volume-card .flex-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.combined-volume-card .bold {
  font-weight: 500;
  font-size: 28px;
}
.combined-volume-card .small {
  font-size: 20px;
}
.combined-volume-card .volume {
  text-align: right;
}
.combined-volume-card .volume .astrix {
  font-weight: 100;
  margin-left: 3px;
}
.combined-volume-card .subscripts {
  font-size: 12px;
}
