.segment-card {
  margin-top: 5px;
  min-height: 100px;
  background-color: var(--seg-box-gray);
  border-radius: var(--steep-border-radius);
  position: relative;
}
.segment-card.new-segment::before {
  content: attr(data-before-content);
  position: absolute;
  background-color: var(--alta-color);
  border-radius: 5px;
  padding: 1px 3px;
  font-size: 12px;
  top: -10px;
  left: -10px;
}
.segment-card.fade-out-keyframe::before {
  animation: fadeInOut 3s linear forwards;
}
.segment-card .title {
  font-size: 16px;
  font-weight: 500;
}
.segment-card.editMode {
  cursor: pointer;
}
.segment-card.selected {
  border: 1px #fff solid;
  animation: blinker 2s linear infinite;
}
.segment-card .segment-overview-card-wrapper {
  display: flex;
  min-height: 100px;
}
.segment-card .segment-overview-card {
  flex-grow: 1;
  padding: var(--card-padding);
  pointer-events: all;
  min-height: 80px;
  display: flex;
  flex-direction: column;
}
.segment-card .segment-overview-card .segment-overview-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.segment-card .segment-overview-card .segment-overview-card-header .subtitle {
  margin-left: 7px;
}
.segment-card .segment-overview-card .segment-overview-card-header .icon-group {
  float: right;
}
.segment-card .segment-overview-card .segment-overview-card-header .icon-group .icon-group-element {
  margin-left: 10px !important;
}
.segment-card .segment-overview-card .segment-overview-card-header .eye-icon {
  opacity: 0.7;
}
.segment-card .segment-overview-card .segment-overview-card-header .eye-icon:hover {
  opacity: 1;
}
.segment-card .segment-overview-card .additional-information {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.segment-card .segment-overview-card .additional-information .further-information {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}
.segment-card .segment-overview-card .additional-information .further-information.left {
  align-items: flex-start;
}
.segment-card .segment-overview-card .additional-information .further-information.right {
  align-items: flex-end;
  min-width: 80px;
}
.segment-card .segment-overview-card .additional-information .further-information span {
  display: block;
}
.segment-card .segment-overview-card .additional-information .further-information .segment-origin {
  opacity: 0.5;
}
.segment-card .segment-overview-card .additional-information .further-information .segment-overview-detail {
  display: flex;
}
.segment-card .segment-overview-card .additional-information .further-information .segment-overview-detail .segment-overview-detail-class {
  font-weight: 500;
  margin-right: 5px;
}
.segment-card .segment-card-footer {
  display: block;
}
.segment-card .segment-card-footer .icon {
  position: relative;
  float: none !important;
}
@-moz-keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes blinker {
  50% {
    border-color: rgba(0,0,0,0);
  }
}
@-webkit-keyframes blinker {
  50% {
    border-color: rgba(0,0,0,0);
  }
}
@-o-keyframes blinker {
  50% {
    border-color: rgba(0,0,0,0);
  }
}
@keyframes blinker {
  50% {
    border-color: rgba(0,0,0,0);
  }
}
