.dropdown-menu {
  font-size: 1em;
  color: var(--text-primary-color);
  font-weight: 400;
  position: relative;
}
.dropdown-menu .dropdown-preview {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: auto;
  padding: 0 20px;
  cursor: pointer;
  border: none;
  background-color: var(--input-background-color);
  color: var(--input-placeholder-color);
  font-size: 10pt;
  font-weight: normal;
  border-radius: 4px;
  transition: all 0.15s ease;
}
.dropdown-menu .dropdown-preview .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.dropdown-menu .dropdown-options {
  position: absolute;
  white-space: nowrap;
  top: 10px;
  left: 10px;
  background-color: #3a3a3a;
  color: #fff;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  border: 1px solid #ccc;
  list-style: none;
  margin: 0;
  padding: 5px;
  border-radius: 7px;
}
.dropdown-menu .dropdown-options li {
  padding: 5px 25px;
  cursor: pointer;
  position: relative;
}
.dropdown-menu .dropdown-options li:hover {
  background-color: #575757;
}
.dropdown-menu .dropdown-options li .icon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-65%);
}
