.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.emph {
  font-weight: 700;
}
.block {
  display: block;
}
.InputDialog .simpleDialog {
  position: relative;
}
.simpleDialog {
  position: relative;
  z-index: 1000;
  border: 0;
  border-radius: 6px;
  background-color: var(--ui-gray-darker);
}
.simpleDialog .header {
  border-bottom: 3px solid var(--primary-background-color);
  padding: 19px 22px 17px;
  position: relative;
}
.simpleDialog .header .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding-right: 40px;
  color: var(--text-secondary-color);
  margin: 0;
}
.simpleDialog .header .closeBtn {
  -webkit-transform: translateY(-50%);
  color: var(--text-secondary-color);
  height: 20px;
  opacity: 1;
  overflow: hidden;
  padding: 2px;
  position: absolute;
  right: 21px;
  text-align: center;
  text-shadow: none;
  top: 50%;
  transform: translateY(-50%);
  transition: color 0.3s ease;
  width: 20px;
}
.simpleDialog .header .closeIcon {
  color: transparent;
  display: block;
  font-size: 0;
  height: 100%;
  line-height: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.simpleDialog .header .closeIcon:after,
.simpleDialog .header .closeIcon:before {
  content: ' ';
  display: block;
  height: 2px;
  transition: background-color 0.3s ease;
  width: 19px;
  background-color: var(--text-secondary-color);
}
.simpleDialog .header .closeIcon:before {
  left: 1px;
  position: absolute;
  top: 1px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 1px 50%;
  transform-origin: 1px 50%;
}
.simpleDialog .header .closeIcon:after {
  right: 1px;
  position: absolute;
  top: 1px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: calc(100% - 1px) 50%;
  transform-origin: calc(100% - 1px) 50%;
}
.simpleDialog .content {
  padding: 16px 22px 25px;
  position: relative;
  color: var(--text-primary-color);
}
.simpleDialog .content .simpleDialogSelect,
.simpleDialog .content .simpleDialogInput {
  background-color: var(--input-background-color);
  color: var(--text-primary-color);
  border: 0;
  border-radius: 2px;
  font-size: 14px;
  height: 30px;
  width: 100%;
  line-height: 16px;
  padding: 8px 9px 6px;
  margin-top: 10px;
  display: block;
}
.simpleDialog .content .simpleDialogLabelFor {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin: 10px 0 0 0;
  color: var(--text-primary-color);
}
.simpleDialog .footer {
  padding: 15px;
  text-align: right;
}
.simpleDialog .footer .btn {
  margin-left: 5px;
}
