.prostate-zone-selector {
  width: 100%;
}
.prostate-zone-selector .zone-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3px;
}
.prostate-zone-selector .zone-selection .prostate-view {
  background-color: var(--seg-box-light-gray) !important;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.prostate-zone-selector .zone-selection .prostate-view:hover {
  color: var(--alta-color);
  transition: all 0.3s ease;
}
.prostate-zone-selector .zone-selection .prostate-view.inactive {
  opacity: 0.3;
  pointer-events: none;
}
.prostate-zone-selector .zone-selection .prostate-view.selected {
  font-weight: 700;
}
.prostate-zone-selector .selected-zone {
  margin-top: 30px;
  display: flex;
  position: relative;
}
.prostate-zone-selector .selected-zone button {
  margin: 0px auto;
}
.prostate-zone-selector .selected-zone .handside-annotation-hook::before {
  content: "R";
  top: -20px;
  left: 10px;
  position: absolute;
}
.prostate-zone-selector .selected-zone .handside-annotation-hook::after {
  content: "L";
  top: -20px;
  right: 10px;
  position: absolute;
}
.prostate-zone-selector .selected-zone #animElems > path,
.prostate-zone-selector .selected-zone #animElems g {
  transition: all 0.3s ease !important;
}
.prostate-zone-selector .selected-zone #animElems > path:hover,
.prostate-zone-selector .selected-zone #animElems g:hover {
  transition: all 0.3s ease !important;
  opacity: 0.8 !important;
}
