.seg-panel-card.bladder .heading {
  margin-top: 20px;
  margin-bottom: 8px;
}
.seg-panel-card.bladder .segment-panel-box > .selector {
  margin-top: 20px;
}
.seg-panel-card.bladder .segmentation-type-area-footer {
  margin-top: 20px;
}
