.partialSequenceMapOverlay {
  position: absolute;
  background-color: #000;
  z-index: 1;
  opacity: 0.6;
  width: 100%;
  height: calc(100% - var(--top-bar-height));
  overflow: hidden;
  top: var(--top-bar-height);
}
