.ddp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ddp-header .icon {
  width: calc(var(--card-icon-size) * 1.2);
  height: calc(var(--card-icon-size) * 1.2);
  color: #fff;
}
