.editing-info {
  margin-top: 5px;
}
.editing-info .info-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;
}
.editing-info .info-list .item-name {
  width: 150px;
  opacity: 0.5;
  display: inline-block;
}
.editing-info .info-list.lb {
  display: block;
}
.editing-info .info-list .item-content {
  font-weight: 500;
}
.editing-info .info-list .textarea-ohif {
  margin-top: 5px;
  border-radius: var(--steep-border-radius);
}
.editing-info .button-group {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.editing-info .button-group button {
  flex: 1;
  padding: 5px 5px;
}
.editing-info .button-group button.active {
  border: 1px solid #fff;
}
.editing-info .further-editing-information-section {
  margin-top: 8px;
  opacity: 0.5;
}
.editing-info .further-editing-information-section.active {
  opacity: 1;
}
