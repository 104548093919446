.report-modal {
  overflow: hidden;
  position: relative;
  min-height: 150px;
}
#download-link {
  font-size: 18px;
  transition: all 0.3s ease;
}
#download-link:hover {
  color: #61cdff;
  transition: all 0.3s ease;
}
.diagram {
  position: absolute;
  z-index: -1;
  width: 500px;
  height: 250px;
  background-color: "white";
}
.lesion-ui {
  position: absolute;
  z-index: -1;
}
.mri-images {
  position: absolute;
  z-index: -1;
}
