.sequence-selector {
  background: var(--box-background-color);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: var(--steep-border-radius);
}
.sequence-selector .sequence-selector-preview {
  position: relative;
  width: 85%;
}
.sequence-selector .selector-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--text-secondary-color-dark);
  margin-bottom: 10px;
}
.sequence-selector .selector-title .algorithm-label {
  margin-left: 20px;
  margin-right: 10px;
  opacity: 50%;
}
.sequence-selector .selector-preview {
  color: #fff;
  cursor: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sequence-selector .icon {
  color: var(--text-secondary-color-dark);
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translate(0%, -50%);
}
.sequence-selector .icon:hover {
  color: var(--alta-color);
}
.sequence-selector .sequence-selector-list {
  border-top: 0.5px solid #000;
  list-style: none;
  padding: 10px;
}
.sequence-selector .sequence-tag {
  margin-bottom: 5px;
  height: 25px;
  color: var(--text-secondary-color-dark);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  transition: all 0.2s ease-in;
}
.sequence-selector .sequence-tag.selected {
  color: #fff;
  font-weight: 700;
}
.sequence-selector .sequence-tag:hover {
  color: var(--alta-color);
  transition: all 0.2s ease-out;
}
