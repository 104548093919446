.SingleDatePicker {
  width: 100%;
}
.SingleDatePicker .SingleDatePicker_picker {
  top: 40px !important;
}
.SingleDatePicker .SingleDatePickerInput {
  width: 100%;
}
.SingleDatePicker .SingleDatePickerInput__withBorder {
  border-radius: 4px;
  border: none;
}
.SingleDatePicker .DateInput_fang {
  display: none;
}
.SingleDatePicker .DateInput {
  width: 100% !important;
  margin: 0;
}
.SingleDatePicker .DateInput>.DateInput_input {
  padding: 0 20px;
  color: #000;
  height: 40px;
  cursor: pointer;
  border: none;
  background-color: #efefef;
  font-weight: normal;
  font-size: 18px;
  border-radius: 4px;
  transition: all 0.15s ease;
  border: none;
}
.SingleDatePicker .DateInput::after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 10px; /* Adjust the size of the triangle */
  border-color: #000 transparent transparent transparent; /* Triangle color */
}
.SingleDatePicker .DateInput:hover::after {
  border-color: var(--alta-color) transparent transparent transparent;
}
.SingleDatePicker .DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
